//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import areaFunction from "@/public/areaFunction";
import sessionStorage from "@/storage/sessionStorage";
import cookie from "@/storage/cookies.js";
import localStorage from "@/storage/localStorage";
import User from "@/public/User.js";
import Header from "@/components/public/Header";
import { getPosition } from "@/pages/Home/components/goods/mapPosition.js";
export default {
  name: "home",
  components: {
    Header, // 头部
    Bottom: () => import("@/components/public/Bottom"), // 尾部
    SearchBox: () => import("@/components/header/SearchMeessagebox")
  },
  // 使用reload刷新 在组件内 export default 下加入 inject: ['reload'],      使用 this.reload() 调用
  provide() {
    return {
      reload: this.reload
    };
  },
  data: function() {
    return {
      // menuList:[],
      isRouterAlive: true,
      fixTop: false,
    };
  },
  computed: {
    imAccount() {
      if (this.$store.state.user && this.$store.state.user.imAccount) {
        return this.$store.state.user.imAccount;
      }
    }
  },
  // 声明reload方法，控制router-view的显示或隐藏，从而控制页面的再次加载
  methods: {
    reload() {
      this.isRouterAlive = false;
      this.$nextTick(function() {
        this.isRouterAlive = true;
      });
    },
    setSession() {
      if (process.client) {
        let id = sessionStorage.read("id");
        if (id == null) {
          let num = new Date().getTime();
          sessionStorage.save("id", num);
          localStorage.clear("search_query");
        }
      }
    },
    async judgeArea() {
      if (process.client) {
        // 判断cookie是否需要修改
        let area = cookie.cookieRead("area");
        let bol = area && area != undefined;
        let bol2;
        // 存在 cookie
        if (bol) {
          bol2 = JSON.parse(area).shortName == undefined; // cookie 为旧数据
          if (!bol2) {
            // 新数据 不需要修改 存入地区列表
            let data = {
              cookie: JSON.parse(area),
              has: true
            };
            this.judgeAreaList(data);
          } else {
            // 旧数据
            let data = {
              cookie: {},
              has: false
            };
            this.judgeAreaList(data);
          }
          return;
        }
        // 使用定位选择当前城市
        let data = {
          cookie: {},
          has: false
        };
        this.judgeAreaList(data);
      }
    },
    async judgeAreaList(data) {
      // 判断 localStorage是否修改 false为修改
      if (process.client) {
        let change = {
          cookie: false,
          localStorage: false
        };

        let bol1 = data.has; // 是否存在 cookie
        if (bol1) {
          change.cookie = true;
        }

        let areaAll = localStorage.read("areaAll");
        let bol2 = areaAll && areaAll != undefined; // 是否存在地区列表
        if (bol2) {
          let bol3 = JSON.parse(areaAll)[0].shortName == undefined; // 是否是旧数据
          if (!bol3) {
            // 存在 新地区列表 不需要修改
            change.localStorage = true;
          }
          if (bol1 && bol2 && !bol3) {
            let oldArea = JSON.parse(cookie.cookieRead("area"));
            let oldAreaAll = JSON.parse(areaAll);
            for (let i = 0; i < oldAreaAll.length; i++) {
              if (oldAreaAll[i].checked) {
                if (oldAreaAll[i].shortName != oldArea.shortName) {
                  change.localStorage = false;
                  break;
                }
              }
            }
          }
        }
        // console.log(change)
        // 不需要修改cookie 个localStorage
        if (change.cookie && change.localStorage) {
          // 不需要修改
          return;
        }
        // console.log(change)
        let areaList = [];
        if (!change.localStorage) {
          // 需要修改localStorage
          areaList = await areaFunction.setAreaList();
        } else {
          // 不需要修改localStorage
          areaList = JSON.parse(areaAll);
        }
        // this.changeArea(areaList, !change.cookie, !change.localStorage);
      }
    },
    changeArea(List, bol1, bol2) {
      if (process.client) {
        // 参数为 地区数组 是否需要修改 cookie 是否需要修改 localStorage
        // 修改cookie 和 localStorage
        if (bol1 && bol2) {
          for (let i = 0; i < List.length; i++) {
            if (List[i].checked) {
              let area = JSON.stringify(List[i]);
              cookie.cookieSet("area", area);
              localStorage.set("areaAll", JSON.stringify(List));
              break;
            }
          }

          return;
        }
        // 修改cookie
        if (bol1) {
          let area = cookie.cookieRead("area");
          let bol = area && area != undefined;
          if (bol) {
            let oldArea = JSON.parse(area);
            for (let i = 0; i < List.length; i++) {
              if (List[i].id == oldArea.id) {
                let newArea = JSON.stringify(List[i]);
                cookie.cookieSet("area", newArea);
                break;
              }
            }
          } else {
            for (let i = 0; i < List.length; i++) {
              if (List[i].checked) {
                let newArea = JSON.stringify(List[i]);
                cookie.cookieSet("area", newArea);
                break;
              }
            }
          }

          return;
        }
        // 修改 localStorage
        if (bol2) {
          if (process.client) {
            let oldArea = JSON.parse(cookie.cookieRead("area"));
            for (let i = 0; i < List.length; i++) {
              if (List[i].id == oldArea.id) {
                List[i].checked = true;
              } else {
                List[i].checked = false;
              }
            }
            localStorage.set("areaAll", JSON.stringify(List));
          }
        }
      }
    },
  },
  async created() {
   let res =  await getPosition()
    console.log('layout', res)
  },
  watch: {
    // "$store.state.user": function(val) {
    //   // 埋点数据
    //   this.$loggerHub([
    //     {
    //       key: "userName",
    //       value:
    //         this.$store.state.user && this.$store.state.user.username
    //           ? this.$store.state.user.username
    //           : "访客",
    //       type:'base'
    //     }
    //   ]);
    // },
    // $route:{
    //   handler(val,oldVal){
    //     if(val.fullPath != oldVal.fullPath){
    //       this.$loggerHub()
    //     }
    //   },
    //   deep:true
    // }
  },
  mounted() {
    // this.$registerStores({store:this.$store})

    //this.$registerImStores({store:this.$store})
    if (process.client) {
      document.addEventListener("scroll", () => {
        this.fixTop =
          document.documentElement.scrollTop > 770 && this.$route.path == "/";
        if (!this.fixTop) {
          this.$refs.fixSearch.blur();
        } else {
          try {
            this.$refs.fixSearch.$refs.searchBox.initQuery();
          } catch (error) {}
        }
      });
      this.judgeArea();
      if (cookie.cookieRead("token")) {
        this.$store.dispatch("setUser");
      }
    }
    // 埋点数据
    // this.$loggerHub([
    //   {
    //     key: "userName",
    //     value: this.$store.state.user.username
    //       ? this.$store.state.user.username
    //       : "访客",
    //       type:'base'
    //   }
    // ]);
  }
};
